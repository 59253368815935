.counter-section {
  width: 100%;
  position: relative;
}
.counter-section .robopack-text {
  position: absolute;
  right: 0;
  top: 30px;
}
.counter_container {
  max-width: 1120px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}
.hero-seeking-text {
  font-family: "Qanelas Soft";
  font-size: 23.36px;
  font-weight: 400;
  line-height: 34px;
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 40px 72px 0;
  position: relative;
  z-index: 1;
}
.hero-seeking-text .seeking-red-color {
  font-family: "Qanelas Soft";
  font-size: 23.36px;
  font-weight: 600;
  line-height: 34px;
  color: #fb6744;
  padding-left: 5px;
}
.hero-seeking-text .seeking-red-yellow {
  font-family: "Qanelas Soft";
  font-size: 23.36px;
  font-weight: 600;
  line-height: 34px;
  color: #fbdc01;
  padding: 0 6px;
}
.counter-days {
  display: none;
  justify-content: center;
  padding: 30px 0 0;
}
.coun-days-hours {
  padding: 0 15px;
}
.coun-days-hours strong {
  display: block;
  width: 100%;
  font-family: "inter-semibold";
  font-size: 20.27px;
  text-align: center;
  padding-bottom: 10px;
  background: linear-gradient(
    135.57deg,
    #0081ee -102.7%,
    #01b9e2 -38.75%,
    #fe5b48 16.08%,
    #f0a039 103.1%,
    #8026c1 153.12%,
    #e73598 217.08%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.counter-number {
  display: flex;
  align-items: center;
  justify-content: center;
}
.counter-number span {
  font-family: "inter-semibold";
  font-size: 76.87px;
  color: #f77841;
  background-color: #fff;
  background-image: url("../assets/images/counter-bg.png");
  background-repeat: no-repeat;
  background-position: top center;
  width: 82px;
  height: 93px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}
/* ***** */
.counter-from {
  max-width: 928px;
  margin: 42px auto 0;
  padding: 24px 20px 24px;
  position: relative;
}
.counter-from:before {
  content: "";
  background: linear-gradient(
    135.57deg,
    #0081ee -102.7%,
    #01b9e2 -38.75%,
    #fe5b48 16.08%,
    #f0a039 103.1%,
    #8026c1 153.12%,
    #e73598 217.08%
  );
  opacity: 0.22;
  backdrop-filter: blur(17px);
  border-radius: 14px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.counter-from-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.counter-from-text {
  width: 33.33%;
  padding: 14px 18px;
  position: relative;
}
.co-from-text {
  background-image: url("../assets/images/counter-text-bg.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;
  padding: 12px 20px;
  position: relative;
}
.co-from-text.text-counter1:before {
  content: "";
  background-image: url("../assets/images/counter-calendar.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  top: 12px;
  width: 45px;
  height: 58px;
}
.co-from-text.text-counter2:before {
  content: "";
  background-image: url("../assets/images/knowledge-icon.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  top: 12px;
  width: 45px;
  height: 58px;
  opacity: 0.5;
}
.co-from-text.text-counter3:before {
  content: "";
  background-image: url("../assets/images/more-icon.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  top: 12px;
  width: 45px;
  height: 65px;
  opacity: 0.3;
}
.co-from-text.text-counter4:before {
  content: "";
  background-image: url("../assets/images/more-icon4.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  top: 12px;
  width: 45px;
  height: 65px;
  opacity: 1;
}
.co-from-text p {
  font-family: "inter-regular";
  font-size: 16px;
  font-weight: 400;
  color: #d5cdcb;
}
.starting-text {
  display: flex;
  font-family: "Qanelas Soft";
  font-size: 30.44px;
  font-weight: 600;
  color: #fff;
  padding-top: 2px;
}
.starting-text span {
  font-family: "Qanelas Soft";
  font-size: 30.44px;
  font-weight: 600;
  color: #fff;
  padding-right: 4px;
}
.starting-text strong {
  font-family: "Qanelas Soft";
  font-size: 30.44px;
  font-weight: 600;
  color: #fff;
  padding-left: 4px;
}
/* ************** */
.price-counter {
  text-align: center;
  padding-top: 48px;
}
.co-month-text {
  font-family: "Qanelas Soft";
  font-size: 30px;
  font-weight: 400;
  color: #bebebe;
  letter-spacing: 0.04px;
  padding-bottom: 22px;
}
.co-month-text strong {
  font-family: "Qanelas Soft";
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.counter-register-btn {
  display: inline-block;
  background: linear-gradient(
    135.57deg,
    #0081ee -102.7%,
    #01b9e2 -38.75%,
    #fe5b48 16.08%,
    #f0a039 103.1%,
    #8026c1 153.12%,
    #e73598 217.08%
  );
  border-radius: 8px;
  font-family: "Qanelas Soft";
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  padding: 18px 62px;
}
.counter-register-btn span {
  font-family: "Qanelas Soft";
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  padding-right: 5px;
  position: relative;
}
.counter-register-btn span.active {
  font-weight: 500;
  padding-right: 0px;
  margin-left: 4px;
}
.counter-register-btn span.active:before {
  content: "";
  background-color: #fff;
  width: 100%;
  height: 3px;
  position: absolute;
  top: 16px;
  left: 0px;
}
.counter-offer-ends-text {
  font-family: "Qanelas Soft";
  font-size: 30px;
  font-weight: 400;
  color: #bebebe;
  padding-top: 6px;
}
.counter-offer-ends-text .offer-time-end {
  font-family: "Qanelas Soft";
  font-size: 30px;
  font-weight: 400;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .counter-section .robopack-text {
    width: 100%;
    padding: 0 20px;
  }
  .counter-section {
    margin-top: 60px;
  }
  .counter-number span {
    font-size: 45px;
    width: 72px;
    height: 62px;
  }
  .hero-seeking-text {
    font-size: 20px;
    line-height: 30px;
  }
  .counter-from-text {
    width: 50%;
  }
  .counter-register-btn {
    font-size: 20px;
    padding: 12px 40px;
  }
  .counter-register-btn span {
    font-size: 22px;
  }
  .co-month-text {
    font-size: 25px;
    padding-bottom: 10px;
  }
  .co-month-text strong {
    font-size: 25px;
  }
  .counter-offer-ends-text {
    font-size: 20px;
    padding-top: 10px;
  }
  .counter-offer-ends-text .offer-time-end {
    font-size: 20px;
  }
  .starting-text {
    font-size: 25px;
  }
  .starting-text span {
    font-size: 25px;
  }
  .starting-text strong {
    font-size: 25px;
  }
}

@media screen and (max-width: 766px) {
  .hero-seeking-text {
    padding: 50px 0 0 0;
    font-size: 19px;
    line-height: 20px;
  }
  .hero-seeking-text .seeking-red-color {
    display: block;
    font-size: 18px;
    line-height: 20px;
    padding-left: 0;
  }
  .hero-seeking-text .seeking-red-yellow {
    font-size: 19px;
    line-height: 20px;
  }
  .counter-from-text {
    width: 100%;
  }
  .counter-section {
    margin-top: -35px;
  }
  .starting-text {
    font-size: 22px;
  }
  .starting-text span {
    font-size: 22px;
  }
  .co-month-text {
    font-size: 20px;
  }
  .co-month-text strong {
    font-size: 20px;
  }
  .counter-register-btn {
    font-size: 20px;
    padding: 12px 25px;
    width: 100%;
  }
  .counter-offer-ends-text {
    font-size: 15px;
  }
  .counter-offer-ends-text .offer-time-end {
    font-size: 15px;
  }
}
