.before-you-ask-section {
  padding: 122px 0;
}
.before-you-ask_container {
  max-width: 1120px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
.before-you-ask-title h2 {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.before-you-ask-title h2 span {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  background: linear-gradient(
    132.64deg,
    #0081ee -15.13%,
    #01b9e2 1.56%,
    #fe5b48 17.6%,
    #fe5b48 33.85%,
    #fdb918 45.27%,
    #f0a039 53.64%,
    #f0a039 71.05%,
    #8026c1 108.71%,
    #e73598 112.88%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.before-you-ask-title p {
  font-family: "Qanelas Soft";
  font-size: 23.36px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  padding-top: 8px;
}
.before-you-ask-text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
}
.before-you-ask-left {
  width: 48%;
}
.before-you-ask-left img {
  border-radius: 10px;
}
.before-you-ask-right {
  width: 48%;
}
.validate-text {
  padding: 20px 0;
}
.validate-text h2 {
  font-family: "Qanelas Soft";
  font-size: 30.44px;
  font-weight: 600;
  color: #fff;
}
.validate-text p {
  font-family: "inter-regular";
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
@media screen and (max-width: 1024px) {
  .also-there-content p {
    font-size: 20px;
  }
}
