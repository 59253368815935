.CheckOut_container {
  max-width: 1120px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
.check-out-title {
  padding-top: 125px;
}
.check-out-title h2 {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.check-out-title h2 span {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  background: linear-gradient(
    132.64deg,
    #0081ee -15.13%,
    #01b9e2 1.56%,
    #fe5b48 17.6%,
    #fe5b48 33.85%,
    #fdb918 45.27%,
    #f0a039 53.64%,
    #f0a039 71.05%,
    #8026c1 108.71%,
    #e73598 112.88%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.check-out-title p {
  font-family: "Qanelas Soft";
  font-size: 23.36px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  padding-top: 8px;
}
.ask-vidio-box {
  text-align: center;
  padding: 50px 0 40px;
}
.ask-vidio-box .ask-vidio {
  max-width: 680px;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  .check-out-title h2 {
    font-size: 48px;
  }
  .check-out-title h2 span {
    font-size: 48px;
  }
  .check-out-title p {
    font-size: 20px;
  }
}
@media screen and (max-width: 766px) {
  .check-out-title {
    padding-top: 38px;
  }
  .check-out-title h2 {
    font-size: 30px;
  }
  .check-out-title h2 span {
    font-size: 30px;
  }
  .ask-vidio-box {
    padding: 30px 0 0;
  }
}
