.mentees__title-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.mentees__title--reg {
  font-family: "Qanelas Soft";
  font-size: 23.36px;
  font-weight: 400;
  text-align: center;
  color: #fff;
}
.slider__item {
  padding: 10px;
}
.mentees__title--bold {
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-size: 30px;
  color: #242421;
}

.previous_carousel .react-multi-carousel-list {
  position: inherit;
}

.mentees__container {
  max-width: 1120px;
  margin: 0 auto 100px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}
.previous_carousel .react-multiple-carousel__arrow--left {
  left: 0;
}
.previous_carousel .react-multiple-carousel__arrow--right {
  right: 0;
}

@media (max-width: 1653px) {
  .previous_carousel .react-multiple-carousel__arrow--left {
    left: 15px !important;
  }
  .previous_carousel .react-multiple-carousel__arrow--right {
    right: 18px !important;
  }
}

@media screen and (max-width: 1024px) {
}

@media (max-width: 766px) {
  .mentees__container {
    padding: 20px 20px;
  }
  .mentees__title-card {
    line-height: 42px;
  }
  .mentees__title--reg {
    font-size: 18px;
    line-height: 22px;
  }
  .mentees__title--bold {
    font-size: 46px;
  }
}

@media (max-width: 414px) {
  .previous_carousel .react-multiple-carousel__arrow--left {
    left: 7px !important;
  }
  .previous_carousel .react-multiple-carousel__arrow--right {
    right: 10px !important;
  }
}
