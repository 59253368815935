img {
  max-width: 100%;
}
.mentor__container {
  max-width: 1120px;
  margin: 0 auto;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mentor {
  width: 100%;
  padding: 100px 0 0;
}
.meet-title-block {
  padding-bottom: 40px;
}
.mentor-block:before {
  content: "";
  background-image: url(../assets/images/meet-your-bg-main.png);
  background-repeat: no-repeat;
  position: absolute;
  top: -66px;
  left: -62px;
  width: 65%;
  height: 210px;
  z-index: -1;
  background-size: 100% 100%;
}
.mentor-block {
  width: 33.33%;
  padding: 10px;
  background-image: url(../assets/images/meet-your-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.mentor__title--text {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-size: 28px;
  letter-spacing: -1.94px;
  text-align: center;
  margin: 0px;
}
.mentor__title-bar .lineYellow-img {
  width: 100%;
}
.mentor--image-block {
  background-image: linear-gradient(#f5f5f5 0em, transparent 0.1em),
    linear-gradient(270deg, #f5f5f5 0em, transparent 0.1em);
  background-size: 1.5em 1.5em;
  z-index: -100;
  padding: 30px 0;
  background-position: center;
}
.mentor--image {
  align-items: center;
  justify-content: center;
}
.mentor__card-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 24px;
  padding-top: 25px;
  padding-bottom: 34px;
}
.mentor__card {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 15px;
  padding: 0px;
  border-radius: 10px;
  z-index: 1;
  height: 100%;
}
.mentor__card-eclps1 {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 0px;
  left: 0px;
  background: #f9d411;
  filter: blur(35px);
  overflow: hidden;
  z-index: -1;
}
.mentor__card-eclps2 {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 40px;
  bottom: 0px;
  overflow: hidden;
  background: #fe8616;
  filter: blur(35px);
  z-index: -1;
}

.mentor__card--name {
  font-family: "Qanelas Soft";
  font-size: 22.92px;
  font-weight: 700;
  color: #fff;
  padding-bottom: 5px;
}

.mentor__card--desc {
  font-family: "Qanelas Soft";
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.mentor__card--image {
  width: 123px;
  height: 123px;
  border-radius: 8px;
  z-index: 5;
  background-color: #fff;
}

.mentor__card--content {
  width: 70%;
  position: relative;
  height: 100%;
}
.linkedin-icon {
  position: absolute;
  bottom: 0;
  left: 0;
}

.meet-your-title {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.meet-your-title span {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  background: linear-gradient(
    132.64deg,
    #0081ee -15.13%,
    #01b9e2 1.56%,
    #fe5b48 17.6%,
    #fe5b48 33.85%,
    #fdb918 45.27%,
    #f0a039 53.64%,
    #f0a039 71.05%,
    #8026c1 108.71%,
    #e73598 112.88%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.mentors-text span {
  font-family: "Manrope", sans-serif;
  font-size: 50px;
  font-weight: 800;
  color: #242121;
  letter-spacing: -0.58px;
}
.mentors-text span img {
  padding-left: 5px;
}

.mentors-from-images {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mentors-from-images .mentor--image-item {
  padding: 0 20px;
}
.mentor__card-container.less__card-container {
  padding-top: 0;
  margin-top: -10px;
}
.mentor .show-more-less-clickable {
  position: relative;
  width: 100%;
  text-align: center;
  display: inline-block;
  height: 50px;
  margin-top: -18px;
}
.mentor .mentor-show-more {
  display: inline-block;
  padding: 8px 20px;
  margin: 0 auto;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border-radius: 10px;
  background: linear-gradient(
    135.57deg,
    #0081ee -102.7%,
    #01b9e2 -38.75%,
    #fe5b48 16.08%,
    #f0a039 103.1%,
    #8026c1 153.12%,
    #e73598 217.08%
  );
}
.mentor-show-more.Less-more {
  margin-top: 25px;
}

@media screen and (max-width: 766px) {
  .linkedin-icon {
    position: inherit;
    margin-top: 6px;
  }
}
