.discover_container {
  max-width: 1696px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}
.discover-content-bar {
  position: relative;
  margin: 80px 0 0;
}
.discover-content-bar:before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135.57deg,
    #0081ee -102.7%,
    #01b9e2 -38.75%,
    #fe5b48 16.08%,
    #f0a039 103.1%,
    #8026c1 153.12%,
    #e73598 217.08%
  );
  opacity: 0.2;
  border-radius: 14px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.discover-title h2 {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  padding: 26px 0 16px;
  margin: 0;
  text-align: center;
}
.discover-title h2 b {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  background: linear-gradient(
    132.64deg,
    #0081ee -15.13%,
    #01b9e2 1.56%,
    #fe5b48 17.6%,
    #fe5b48 33.85%,
    #fdb918 45.27%,
    #f0a039 53.64%,
    #f0a039 71.05%,
    #8026c1 108.71%,
    #e73598 112.88%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.discover-title p {
  font-family: "Qanelas Soft";
  font-size: 23.36px;
  font-weight: 400;
  color: #fff;
  text-align: center;
}
/* ******************** */
.discover-text-bar {
  display: flex;
  align-items: end;
  max-width: 1015px;
  margin: 0 auto;
  padding-top: 38px;
}
.discover-left-img {
  width: 20%;
  margin-right: 20px;
}
.discover-left-img img {
  display: block;
}
.discover-ul-text {
  width: 80%;
  padding-bottom: 30px;
}
.check-apply-text {
  display: inline-block;
  width: 100%;
}
.discover-ul-text .apply-text {
  display: block;
  width: auto;
  position: relative;
  padding-left: 55px;
  margin: 12px 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Qanelas Soft";
  font-size: 30.38px;
  font-weight: 400;
  color: #fff;
}
.discover-ul-text .apply-text input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 25px;
  width: 25px;
  border: 0.843891px solid #ffffff;
  border-radius: 5.90724px;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.discover-ul-text .apply-text input:checked ~ .checkmark:after {
  display: block;
}
.discover-ul-text .apply-text .checkmark:after {
  content: "";
  left: 3px;
  top: -2px;
  width: 30px;
  height: 30px;
  background-image: url("../assets/images/checkmark-icon.png");
  background-repeat: no-repeat;
  background-position: top center;
}

@media screen and (max-width: 1024px) {
  .discover-content-bar {
    padding: 0 50px;
  }
  .discover-title h2 {
    font-size: 48px;
  }
  .discover-title h2 b {
    font-size: 48px;
    padding-left: 8px;
  }
  .discover-title p {
    font-size: 20px;
  }
  .discover-ul-text .apply-text {
    font-size: 18px;
    padding-left: 40px;
  }
}
@media screen and (max-width: 766px) {
  .discover-content-bar {
    margin-top: 45px;
    padding: 0 20px;
  }
  .discover-title h2 {
    font-size: 30px;
    padding-bottom: 8px;
  }
  .discover-title h2 b {
    font-size: 30px;
  }
  .discover-text-bar {
    flex-wrap: wrap;
    padding-top: 20px;
  }
  .discover-left-img {
    width: 100%;
    margin-right: 0;
    order: 2;
  }
  .discover-ul-text {
    width: 100%;
    padding-bottom: 20px;
  }
  .discover-ul-text .apply-text {
    padding-left: 25px;
  }
  .discover-left-img img {
    display: block;
    margin: 0 auto;
    max-width: 200px;
  }
  .discover-ul-text .apply-text .checkmark::after {
    content: "";
    left: 1px;
    top: -1px;
    width: 14px;
    height: 10px;
    background-size: 100%;
  }
}
