.TrustCarousel-section {
  display: inline-block;
  width: 100%;
}
.stories__title-card {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
  margin-bottom: 15px;
}

.stories__text--reg {
  font-family: "inter-regular";
  font-size: 24px;
  color: #242121;
}

.stories__text--bold {
  font-family: "inter-ebold";
  font-size: 30px;
  color: #242121;
}

.slider__item {
  /* align-items: center; */
  /* background-color: red; */
  padding: 10px;
}
.TrustCarousel-mobile {
  display: none;
}

.TrustCarousel-mobile .react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5) !important;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}
.TrustCarousel-mobile
  .stories__container
  .react-multiple-carousel__arrow--left::before {
  content: "\e824" !important;
  background-image: none;
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: none;
}
.TrustCarousel-mobile
  .stories__container
  .react-multiple-carousel__arrow--right::before {
  content: "\e825" !important;
  background-image: none;
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: none;
}

@media (min-width: 767px) {
  .stories__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .stories {
    width: 80%;
  }

  .stories__text--reg {
    font-size: 30px;
  }

  .stories__text--bold {
    font-size: 30px;
  }
}

@media (min-width: 1023px) {
  .stories__text--reg {
    font-size: 40px;
  }

  .stories__text--bold {
    font-size: 40px;
  }
}

@media screen and (max-width: 766px) {
  .TrustCarousel-mobile {
    display: block;
    margin: 20px 0 10px;
  }
  .TrustCarousel-mobile .stories__container {
    padding: 0;
  }
}
