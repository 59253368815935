.stories__container {
  max-width: 1215px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.stories_title-card {
  text-align: center;
  padding-bottom: 18px;
}
.stories_title-card .stories_text_reg {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  color: #fff;
}
.stories_title-card .stories_text_reg span {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  background: linear-gradient(
    132.64deg,
    #0081ee -15.13%,
    #01b9e2 1.56%,
    #fe5b48 17.6%,
    #fe5b48 33.85%,
    #fdb918 45.27%,
    #f0a039 53.64%,
    #f0a039 71.05%,
    #8026c1 108.71%,
    #e73598 112.88%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.stories__container .react-multiple-carousel__arrow::before {
  display: block;
  text-align: center;
  z-index: 2;
  position: relative;
  font-size: 0;
  width: 48px;
  height: 48px;
}
.stories__container .react-multiple-carousel__arrow:hover:before {
  opacity: 0.5;
}

.stories__container .react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: transparent !important;
  min-width: inherit;
  min-height: inherit;
  opacity: 1;
  cursor: pointer;
}
.stories__container .react-multiple-carousel__arrow--left {
  left: 0;
}
.stories__container .react-multiple-carousel__arrow--right {
  right: 0;
}
.stories__container .react-multiple-carousel__arrow--left:before {
  background-image: url("../assets/images/next-button.png");
  background-repeat: no-repeat;
  background-position: top center;
  left: 0;
}
.stories__container .react-multiple-carousel__arrow--right:before {
  background-image: url("../assets/images/previous-button.png");
  background-repeat: no-repeat;
  background-position: top center;
  right: 0;
}
/* **************** */

/* --------------------------- */
.hear-previou-top-bar {
  display: flex;
  align-items: start;
  justify-content: center;
}
.hear-previou-top-bar .client-img {
  padding: 0 15px;
}
.client-img.top-margin {
  margin-top: 38px;
}
.hear-previou-top-bar .client-img img {
  width: 84px;
  height: 84px;
  border-radius: 100%;
  border: solid 2px transparent;
}
.hear-previou-top-bar .client-img.active img {
  border: solid 2px red;
  padding: 3px;
}
.hear-previou-bottom-bar {
  text-align: center;
}
.prev-text-carousel {
  animation-name: example;
  animation-duration: 4s;
}
@keyframes example {
  0% {
    left: 0px;
    top: 0px;
  }
  25% {
    left: 200px;
    top: 0px;
  }
}
/* --------------------------- */

.previous-content {
  padding-top: 45px;
}
.previous-successful-carousel {
  text-align: center;
}
.previous-content .prev-text-carousel h2 {
  display: block;
  width: 100%;
  font-family: "Qanelas Soft";
  font-size: 40px;
  font-weight: 500;
  color: #f97211;
  padding-top: 10px;
  padding-bottom: 2px;
}
.previous-content .prev-text-carousel h4 {
  display: block;
  width: 100%;
  font-family: "Qanelas Soft";
  font-size: 20px;
  font-weight: 400;
  color: #bebebe;
}
.previous-content .prev-text-carousel p {
  font-family: "Qanelas Soft";
  font-size: 20px;
  font-weight: 400;
  color: #bebebe;
  letter-spacing: 0.04px;
  padding-top: 35px;
  max-width: 840px;
  margin: 0 auto;
}
/* ----------------- */
.hear-previou-bottom-bar .swiper.swiper-initialized {
  display: flex;
  flex-wrap: wrap;
}
.hear-previou-bottom-bar .swiper-pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  order: 1;
  margin-bottom: 42px;
}
.hear-previou-bottom-bar .swiper-pagination .swiper-pagination-bullet {
  padding: 0 15px;
}
.hear-previou-bottom-bar .swiper-pagination .swiper-pagination-bullet img {
  border: solid 3px transparent;
  border-radius: 100%;
  padding: 5px;
  width: 84px;
  height: 84px;
  opacity: 0.5;
}
.hear-previou-bottom-bar
  .swiper-pagination
  .swiper-pagination-bullet:nth-child(2) {
  margin-top: 38px;
}
.hear-previou-bottom-bar
  .swiper-pagination
  .swiper-pagination-bullet:nth-child(4) {
  margin-top: 38px;
}
.hear-previou-bottom-bar
  .swiper-pagination
  .swiper-pagination-bullet:nth-child(6) {
  margin-top: 38px;
}
.hear-previou-bottom-bar
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active
  img {
  border: solid 3px #f58a3e;
  opacity: 1;
}
.hear-previou-bottom-bar .swiper-wrapper {
  order: 3;
}

.hear-previou-bottom-bar .swiper-button-prev {
  left: 0;
  width: 48px;
}
.hear-previou-bottom-bar .swiper-button-prev:after {
  content: "";
  background-image: url("../assets/images/next-button.png");
  background-repeat: no-repeat;
  background-position: top center;
  left: 0;
  width: 48px;
  height: 48px;
}
.hear-previou-bottom-bar .swiper-button-next {
  right: 0;
  width: 48px;
}

.hear-previou-bottom-bar .swiper-button-next:after {
  content: "";
  background-image: url("../assets/images/previous-button.png");
  background-repeat: no-repeat;
  background-position: top center;
  right: 0;
  width: 48px;
  height: 48px;
}

@media screen and (max-width: 1024px) {
  .stories_title-card .stories_text_reg {
    font-size: 48px;
  }
  .stories_title-card .stories_text_reg span {
    font-size: 48px;
  }
}
@media screen and (max-width: 766px) {
  .stories_title-card {
    padding-bottom: 0;
  }
  .stories_title-card .stories_text_reg {
    font-size: 30px;
  }
  .stories_title-card .stories_text_reg span {
    font-size: 30px;
  }
  .hear-previou-bottom-bar .swiper-pagination .swiper-pagination-bullet {
    padding: 0 3px;
  }
  .previous-content .prev-text-carousel h2 {
    font-size: 34px;
  }
  .previous-content .prev-text-carousel p {
    font-size: 18px;
    padding-top: 8px;
  }
  .hear-previou-bottom-bar .swiper-pagination {
    margin-bottom: 20px;
  }
  .prev-text-carousel .dauble-quote-icon {
    width: 38px;
  }
  .hear-previou-bottom-bar .swiper-button-prev {
    left: 0;
    top: 26%;
    width: 20px;
  }
  .hear-previou-bottom-bar .swiper-button-prev:after {
    content: "";
    background-image: url("../assets/images/next-button.png");
    background-size: 100%;
    left: 0;
    width: 20px;
    height: 20px;
  }
  .hear-previou-bottom-bar .swiper-button-next {
    right: 0;
    top: 26%;
    width: 20px;
  }
  .hear-previou-bottom-bar .swiper-button-next:after {
    content: "";
    background-image: url("../assets/images/previous-button.png");
    background-size: 100%;
    right: 0;
    width: 20px;
    height: 20px;
  }
  .hear-previou-bottom-bar .previous-content {
    padding-top: 25px;
  }
  .hear-previou-bottom-bar .swiper-pagination .swiper-pagination-bullet img {
    height: 78px;
  }
}

@media screen and (max-width: 569px) {
  .hear-previou-bottom-bar .swiper-pagination .swiper-pagination-bullet img {
    border: solid 2px transparent;
    border-radius: 100%;
    padding: 2px;
    height: 48px;
  }
  .hear-previou-bottom-bar
    .swiper-pagination
    .swiper-pagination-bullet.swiper-pagination-bullet-active
    img {
    border: solid 2px #f58a3e;
  }
  .hear-previou-bottom-bar
    .swiper-pagination
    .swiper-pagination-bullet:nth-child(2) {
    margin-top: 25px;
  }
  .hear-previou-bottom-bar
    .swiper-pagination
    .swiper-pagination-bullet:nth-child(4) {
    margin-top: 25px;
  }
  .hear-previou-bottom-bar
    .swiper-pagination
    .swiper-pagination-bullet:nth-child(6) {
    margin-top: 25px;
  }
  .previous-content .prev-text-carousel h2 {
    font-size: 30px;
  }
}
