.css-bye7ap-MuiPaper-root-MuiSnackbarContent-root {
  border-radius: 70px !important;
}
.load-popup-main {
  display: flex;
  position: relative;
}
.load-popup-main .right-icon {
  position: absolute;
  left: -30px;
  top: -13px;
  width: 90px;
}
.load-popup-main .load-popup-text {
  padding-left: 72px;
}
.load-popup-main .load-popup-text h2 {
  margin: 0;
  padding: 0;
}
.load-popup-main .load-popup-text p {
  margin: 0;
  padding: 0;
}
.load-popup-main .load-popup-text span {
  font-size: 13px;
  font-weight: 400;
}

@media screen and (max-width: 766px) {
  .load-popup-main .right-icon {
    position: absolute;
    left: -10px;
    top: -7px;
    width: 70px;
  }
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
