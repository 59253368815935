.navbar {
  width: 100%;
  max-width: 1920px;
  padding-left: 60px;
  padding-right: 60px;
}
.navbar__container {
  background-color: #080808;
}
.navbar__container {
  padding: 20px 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 1050;
}

.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.header-left {
  display: flex;
  align-items: center;
}
.navbar__logo {
  width: 214px;
}
.navbar__links {
  display: none;
}

.navbar__ham {
  margin-top: -10px;
}

.navbar__icon {
  font-size: 24px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #fff;
  z-index: 10;
  padding: 10px;
}

.modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mobile--link {
  text-align: center;
  font-size: 15px;
  text-decoration: none;
  color: #000;
  font-family: "inter-ebold";
}

.close-icon {
  transform: rotate(90deg);
  font-size: 20px;
}

.web--link {
  font-family: "inter-regular";
  text-decoration: none;
  color: #fff;
  font-size: 19.56px;
  font-weight: 400;
}
.web--link:hover {
  color: #fadc00;
}
.navbar .header-btns {
  display: flex;
  background: #fff;
  border-radius: 26px;
}
.header-btns .web--link-btn {
  font-family: "inter-regular";
  color: #000;
  border: none;
  padding: 15px 32px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-btns .web--link-btn.queries-btn {
  background: #000;
  border-radius: 26px;
  color: #fff;
  position: relative;
  left: -1px;
}
.header-btns .web--link-btn.queries-btn {
  position: relative;
  right: -1px;
}

.header-marquee-section {
  display: inline-block;
  width: 100%;
  background: #fbdc01;
  padding: 3px 0;
  margin-top: 20px;
}
.header-marquee-section .header-marquee {
  font-family: "Qanelas Soft";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.04px;
  color: #080808;
  padding: 3px 10px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-marquee-section .header-marquee .marquee-icon {
  position: relative;
  top: 0px;
  margin-right: 10px;
}
.modal-overlay .close-icon {
  font-size: 30px;
  cursor: pointer;
}

@media (min-width: 767px) {
  .navbar__container {
    justify-content: center;
  }

  .navbar__ham {
    display: none;
  }
  .navbar__links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: -5px;
    margin-left: 35px;
  }

  .navbar__links .web--link {
    padding: 0 21px;
  }
}

@media screen and (max-width: 1339px) {
  .equation__content-block {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media screen and (max-width: 1139px) {
  .navbar__links .web--link {
    padding: 0 12px;
    font-size: 15px;
  }
  .header-btns .web--link-btn {
    font-size: 14px;
    padding: 15px 20px;
  }
}
@media screen and (max-width: 1024px) {
  .navbar {
    padding-left: 20px;
    padding-right: 20px;
  }
  .navbar__logo {
    width: 170px;
  }
  .navbar__links .web--link {
    font-size: 14px;
    padding: 0 10px;
  }
  .header-btns .web--link-btn {
    font-size: 12px;
    padding: 10px 15px;
  }
  .header-marquee-section .header-marquee {
    font-size: 15px;
  }
}
@media screen and (max-width: 766px) {
  .navbar__container {
    background: #000000b0;
  }
  .navbar .header-btns {
    display: none;
  }
  .modal-overlay {
    height: 40%;
  }
  .mobile--link {
    font-size: 15px;
    text-align: left;
    border-top: solid 1px #000;
    padding: 11px 0 0;
  }
  .header-marquee-section .header-marquee {
    font-size: 16px;
  }
  .web--link {
    color: #000;
    border-top: solid 1px #000;
    padding: 5px 0 0;
  }
}
