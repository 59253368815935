.accordion-text-bar {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 26px 20px;
  background-image: url("../assets/images/according-bg.png");
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
}

.accordion__title-card {
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 15px;
  /* justify-content: space-between; */
}

.accr--title {
  font-family: "Manrope", sans-serif;
  color: #242121;
  font-size: 14px;
  font-weight: 800;
  width: 80%;
}

.accr--icon {
  font-size: 22px;
}

.accr-desc {
  font-family: "Manrope", sans-serif;
  font-size: 28px;
  font-weight: 400;
  color: #242121;
  font-size: 13px;
  border-top: 0.5px solid #ebebeb;
  width: 100%;
  max-width: 485px;
  padding: 10px 15px;
}
.accr-desc:first-child {
  border-top: none;
}

.accordion__content-card {
  border-top: 0px;
  padding: 15px 77px;
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: -10px 0 0 0;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 36.8718px 13.8269px rgba(0, 0, 0, 0.01),
    0px 19.9722px 12.2906px rgba(0, 0, 0, 0.03),
    0px 9.21795px 9.21795px rgba(0, 0, 0, 0.04),
    0px 1.53632px 4.60897px rgba(0, 0, 0, 0.05);
}

.accordion-elps1 {
  position: absolute;
  width: 60px;
  height: 80px;
  right: 50px;
  top: 0px;

  background: #f9d411;
  filter: blur(30px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.accordion-elps2 {
  position: absolute;
  width: 50px;
  height: 80px;
  right: 0px;
  top: 10px;

  background: #fe8616;
  filter: blur(20px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: -5;
}

.fugaz {
  color: #fff;
  position: absolute;
  right: 4px;
  top: -60px;
  font-family: "fugaz-regular";
  font-size: 70px;
  transform: rotate(5deg);
  z-index: 1;
  opacity: 0.5;
}

@media (max-width: 766px) {
  .timeline {
    padding-top: 12px;
  }
  .accordion-text-bar {
    padding: 0px;
  }
  .accr--title {
    font-size: 19px;
  }
  .accr-desc {
    font-size: 17px;
  }
  .fugaz {
    right: 0;
    top: -10px;
    font-size: 65px;
  }
  .timeline .timeline--line {
    width: 100% !important;
  }
  .accordion__content-card {
    padding: 15px 28px;
  }
  .timeline__accordions {
    gap: 12px;
  }
}

@media (min-width: 767px) {
  .accr--icon {
    font-size: 26px;
  }

  .accr--title {
    font-family: "Manrope", sans-serif;
    font-size: 30px;
  }

  .accr-desc {
    font-size: 28px;
  }

  .fugaz {
    font-size: 180px;
    transform: rotate(5deg);
  }
}
