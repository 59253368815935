.internship-carousel-section {
  padding: 125px 0 0;
}
.internship-title {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.internship-title span {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  background: linear-gradient(
    132.64deg,
    #0081ee -15.13%,
    #01b9e2 1.56%,
    #fe5b48 17.6%,
    #fe5b48 33.85%,
    #fdb918 45.27%,
    #f0a039 53.64%,
    #f0a039 71.05%,
    #8026c1 108.71%,
    #e73598 112.88%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.internship-carousel-content-bar {
  position: relative;
  padding: 28px 80px;
  margin: 40px 0 0;
}
.internship-carousel-content-bar:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135.57deg,
    #0081ee -102.7%,
    #01b9e2 -38.75%,
    #fe5b48 16.08%,
    #f0a039 103.1%,
    #8026c1 153.12%,
    #e73598 217.08%
  );
  opacity: 0.22;
  backdrop-filter: blur(17px);
}
.internship-carousel-logos {
  display: flex;
  align-items: center;
  justify-content: center;
}
.internship-logo {
  padding: 0 50px;
}
.internship-logo img {
  display: block;
}

@media screen and (max-width: 1024px) {
  .internship-carousel-section {
    padding: 80px 0 0;
  }
  .internship-title {
    font-size: 48px;
  }
  .internship-title span {
    font-size: 48px;
  }
  .internship-carousel-content-bar {
    padding: 20px 40px;
  }
}

@media screen and (max-width: 766px) {
  .internship-title {
    font-size: 30px;
    padding: 0 20px;
  }
  .internship-title span {
    font-size: 30px;
  }
  .internship-carousel-section {
    padding: 40px 0 0;
  }
  .internship-carousel-content-bar {
    padding: 20px 15px;
    margin-top: 20px;
  }
}
