/* @font-face {
  font-family: "inter-regular";
  src: local("Inter-Regular"),
    url("./assets/fonts/Inter-Regular.ttf") format("truetype");
} */
@font-face {
  font-family: "inter-regular";
  src: local("Manrope-Regular"),
    url("./assets/fonts/Manrope-Regular.ttf") format("truetype");
}

/* @font-face {
  font-family: "inter-semibold";
  src: local("Inter-SemiBold"),
    url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
} */
@font-face {
  font-family: "inter-semibold";
  src: local("Manrope-SemiBold"),
    url("./assets/fonts/Manrope-SemiBold.ttf") format("truetype");
}

/* @font-face {
  font-family: "inter-bold";
  src: local("Inter-Bold"),
    url("./assets/fonts/Inter-Bold.ttf") format("truetype");
} */
@font-face {
  font-family: "inter-bold";
  src: local("Manrope-Bold"),
    url("./assets/fonts/Manrope-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "inter-ebold";
  src: local("Manrope-ExtraBold"),
    url("./assets/fonts/Manrope-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "inter-medium";
  src: local("Manrope-Medium"),
    url("./assets/fonts/Manrope-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "inter-light";
  src: local("Manrope-Light"),
    url("./assets/fonts/Manrope-Light.ttf") format("truetype");
}

@font-face {
  font-family: "fugaz-regular";
  src: local("FugazOne-Regular"),
    url("./assets/fonts/FugazOne-Regular.ttf") format("truetype");
}
/* ************* */
@font-face {
  font-family: "Qanelas Soft";
  src: url("./assets/fonts/QanelasSoft-Bold.woff2") format("woff2"),
    url("./assets/fonts/QanelasSoft-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Qanelas Soft";
  src: url("./assets/fonts/QanelasSoft-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/QanelasSoft-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Qanelas Soft";
  src: url("./assets/fonts/QanelasSoft-Light.woff2") format("woff2"),
    url("./assets/fonts/QanelasSoft-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Qanelas Soft";
  src: url("./assets/fonts/QanelasSoft-Black.woff2") format("woff2"),
    url("./assets/fonts/QanelasSoft-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Qanelas Soft";
  src: url("./assets/fonts/QanelasSoft-ExtraBoldItalic.woff2") format("woff2"),
    url("./assets/fonts/QanelasSoft-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Qanelas Soft";
  src: url("./assets/fonts/QanelasSoft-Heavy.woff2") format("woff2"),
    url("./assets/fonts/QanelasSoft-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Qanelas Soft";
  src: url("./assets/fonts/QanelasSoft-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/QanelasSoft-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Qanelas Soft";
  src: url("./assets/fonts/QanelasSoft-Regular.woff2") format("woff2"),
    url("./assets/fonts/QanelasSoft-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Qanelas Soft";
  src: url("./assets/fonts/QanelasSoft-Medium.woff2") format("woff2"),
    url("./assets/fonts/QanelasSoft-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Qanelas Soft";
  src: url("./assets/fonts/QanelasSoft-UltraLight.woff2") format("woff2"),
    url("./assets/fonts/QanelasSoft-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "inter-regular";
  font-size: 14px;
}
body {
  padding: 0;
  margin: 0;
  background-image: url("./assets/images/main-bg.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #0f0f0f;
  height: 3000px;
}

@media screen and (max-width: 766px) {
  body {
    background-image: url("./assets/images/mobile-bg.png");
    background-size: 100%;
  }
}
@media (max-width: 426px) {
  * {
    font-size: 13px;
  }
}

@media (max-width: 376px) {
  * {
    font-size: 12px;
  }
}
