.footer__container {
  background: linear-gradient(180deg, #171717 0%, #000000 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.footer {
  max-width: 620px;
  margin: 0 auto;
}
.footer--image-block {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.footer__container p {
  font-family: "Manrope", sans-serif;
  font-size: 21.64px;
  font-weight: 400;
  color: #fff;
}
.footer__contact-card p {
  color: #9f9f9f;
  padding: 4px 0;
}

.footer__logo-image {
  margin-bottom: 10px;
}

.footer__contact-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.footer__brand--container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer__brand-icon {
  font-size: 28px;
}

.foot--txt {
  margin-bottom: 20px;
}

.footer-Follow-us {
  justify-content: center;
  padding-top: 42px;
  margin-bottom: 30px !important;
}
.footer-Follow-us .footer-follow-text {
  display: block;
  width: 100%;
  font-family: "Manrope", sans-serif;
  font-size: 23.02px;
  font-weight: 400;
  color: #fff;
}
.footer-Follow-us .footer__brand--container p {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-size: 25px !important;
}
.my-equation-text {
  font-size: 25px;
  color: #fff;
  text-decoration: none;
}
.footer__container p.footer-copyright-text {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  letter-spacing: -0.5px;
}
.footer__container p.foot--txt {
  padding-top: 7px;
}

.footer-search {
  width: 33.33%;
  position: relative;
}
.footer-search input {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #9f9f9f;
  background: #1a1a1a;
  border-radius: 30.9053px;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0 55px 0 25px;
  width: 100%;
  height: 60px;
}
.footer-search input::placeholder {
  font-size: 16px;
  color: #9f9f9f !important;
}
.footer-search-icon {
  position: absolute;
  top: 12px;
  right: 10px;
  cursor: pointer;
}
.footer-search-icon:hover {
  opacity: 0.8;
}
.footer-social-icon {
  display: flex;
  align-items: center;
}
.footer-social-icon a {
  margin: 0 5px;
}
@media screen and (max-width: 1024px) {
  .footer__container p {
    font-size: 18px;
  }
  .footer__contact-card p {
    padding: 2px 0;
  }
  .footer-Follow-us .footer__brand--container p {
    font-size: 23px !important;
  }
  .my-equation-text {
    font-size: 23px !important;
  }
  .footer-search input::placeholder {
    font-size: 14px;
  }
}
@media (max-width: 766px) {
  .footer__container {
    padding: 40px 20px 30px;
    margin-top: 30px;
  }
  .footer--image-block {
    text-align: left;
  }
  .footer__container p {
    font-size: 14px;
    line-height: 21px;
  }
  .footer-Follow-us .footer-follow-text {
    font-size: 16px;
    line-height: 20px;
  }
  .footer-Follow-us .footer__brand--container p {
    font-size: 20px !important;
  }
  .my-equation-text {
    font-size: 20px !important;
  }
  .footer__brand-icon {
    font-size: 20px;
  }
  .footer__contact-container .footer__contact-card p {
    font-size: 15px;
  }
  .footer__contact-container {
    flex-wrap: wrap;
  }
  .footer__contact-card {
    width: 50%;
  }
  .footer-search {
    width: 100%;
    max-width: 260px;
    margin: 45px auto 0;
  }
  .footer-search input {
    font-size: 13px;
    height: 52px;
  }
  .footer-search-icon {
    top: 7px;
  }
  .footer-Follow-us {
    padding-top: 20px;
  }
  .footer-social-icon a {
    width: 22px;
  }
  .footer-Follow-us .footer__brand--container p {
    font-size: 18px !important;
  }
  .my-equation-text {
    font-size: 18px !important;
  }
  .footer__container p.footer-copyright-text {
    font-size: 14px;
  }
}
@media (max-width: 414px) {
  .footer__contact-container .footer__contact-card p {
    font-size: 14px;
  }
}

@media (min-width: 767px) {
  .footer__container {
    margin-top: 0px;
  }
  .footer--middle {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1197px) {
  .footer__container {
    padding: 40px;
  }
}
