.faq-accordion__container {
  /* padding: 10px; */
  position: relative;
}

.faq-accordion-text {
  display: flex;
  justify-content: space-between;
  padding: 24px 40px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 24px 9px rgba(0, 0, 0, 0.01), 0px 13px 8px rgba(0, 0, 0, 0.03),
    0px 6px 6px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.05);
  position: relative;
}
.faq-accordion__title-card {
  width: 100%;
  justify-content: space-between;
}
.faq-accordion__title-card p {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-size: 15px;
  color: #242121;
  width: 80%;
}

.faq-accordion__content-card {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #242121;
  font-size: 15px;
  margin-top: -10px;
  padding: 42px 40px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  box-shadow: 0px 24px 9px rgba(0, 0, 0, 0.01), 0px 13px 8px rgba(0, 0, 0, 0.03),
    0px 6px 6px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.05);
}

.faq-accordion__content-card p {
  font-family: "Manrope", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 36px;
}

.faq--icon {
  font-size: 24px;
}

.adc {
  position: absolute;
  width: 70px;
  height: 70px;
  top: 0px;
  right: -5px;
  z-index: -1;

  background: #fe8616;
  filter: blur(35px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

@media (max-width: 766px) {
  .faq-accordion__title-card p {
    font-size: 16px;
  }
  .faq-accordion-text {
    padding: 18px;
  }
  .faq-accordion__content-card {
    padding: 32px 18px;
  }
  .faq-accordion__content-card p {
    font-size: 16px;
    line-height: 21px;
  }
}

@media (min-width: 767px) {
  .faq-accordion__title-card p {
    font-size: 28px;
  }

  .faq-accordion__content-card {
    font-size: 22px;
  }
}

@media (min-width: 1023px) {
  .faq-accordion__title-card p {
    font-size: 28px;
  }

  .faq-accordion__content-card {
    font-size: 22px;
  }
}
