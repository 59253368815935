.future-section {
  padding: 125px 0 115px;
}
.future_container {
  max-width: 1120px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
.future-reserveseat .price-counter {
  display: flex;
  align-items: center;
  padding: 0;
}
.future-reserveseat .price-counter a {
  width: 45%;
  margin: 0 20px;
}
.future-reserveseat .price-counter .counter-register-btn {
  margin: 0px;
  width: 100%;
  padding: 18px 0;
}
.future-reserveseat .price-counter .co-month-text {
  text-align: right;
  padding: 0;
  width: 30%;
}
.future-reserveseat .price-counter .co-month-text strong {
  display: block;
  width: 100%;
}
.future-reserveseat .price-counter .counter-offer-ends-text {
  font-size: 30px;
  text-align: left;
  width: 25%;
}
.future-reserveseat .price-counter .counter-offer-ends-text .offer-time-end {
  font-size: 30px;
}
/* *************** */
.future-content {
  display: inline-block;
  width: 100%;
  padding: 122px 0 0;
}
.future-title {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.future-title span {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  background: linear-gradient(
    132.64deg,
    #0081ee -15.13%,
    #01b9e2 1.56%,
    #fe5b48 17.6%,
    #fe5b48 33.85%,
    #fdb918 45.27%,
    #f0a039 53.64%,
    #f0a039 71.05%,
    #8026c1 108.71%,
    #e73598 112.88%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.feature-text-section {
  width: 100%;
  position: relative;
  padding: 35px 110px;
  margin-top: 35px;
}
.feature-text-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135.57deg,
    #0081ee -102.7%,
    #01b9e2 -38.75%,
    #fe5b48 16.08%,
    #f0a039 103.1%,
    #8026c1 153.12%,
    #e73598 217.08%
  );
  opacity: 0.22;
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  border-radius: 14px;
  z-index: -1;
}

.feature_card-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 24px;
}
.feature_card-block {
  width: 33.33%;
  padding: 15px 18px;
  background-image: url(../assets/images/meet-your-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.feature_card-block:before {
  content: "";
  background-image: url(../assets/images/meet-your-bg-main.png);
  background-repeat: no-repeat;
  position: absolute;
  top: -66px;
  left: -62px;
  width: 65%;
  height: 210px;
  z-index: -1;
  background-size: 100% 100%;
}
.feature-mentor-text p {
  font-family: "Qanelas Soft";
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  line-height: 21px;
  padding-top: 5px;
}
.feature-mentor-text .fe-img-icon {
  width: 40px;
  height: 40px;
}
.feature-ul-bar {
  display: flex;
  align-items: self-start;
  padding-top: 30px;
}
.feature-ul-bar ul {
  width: 45%;
  padding: 0;
  margin: 0;
  list-style: none;
}
.feature-ul-bar ul:first-child {
  width: 55%;
}
.feature-ul-bar ul li {
  font-family: "Qanelas Soft";
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  position: relative;
  margin: 12px 0;
  padding-left: 40px;
}
.feature-ul-bar ul li:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url("../assets/images/checkmark-icon.png");
  background-repeat: no-repeat;
  background-position: top center;
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 1024px) {
  .future-content {
    padding: 70px 0;
  }
  .future-title {
    font-size: 48px;
  }
  .future-title span {
    font-size: 48px;
  }
  .feature_card-container {
    flex-wrap: wrap;
  }
  .feature_card-block {
    width: 100%;
  }
  .feature-ul-bar ul li {
    font-size: 16px;
  }
  .future-section {
    padding: 60px 0 20px;
  }
  .future-reserveseat .price-counter {
    flex-wrap: wrap;
  }
  .future-reserveseat .price-counter .co-month-text {
    width: 100%;
    text-align: center;
    padding-bottom: 15px;
  }
  .future-reserveseat .price-counter .counter-offer-ends-text {
    font-size: 24px;
    width: 100%;
    text-align: center;
  }
  .future-reserveseat .price-counter a {
    margin: 0 auto;
  }
  .future-reserveseat .price-counter .counter-offer-ends-text .offer-time-end {
    font-size: 24px;
  }
  .future-reserveseat .price-counter .co-month-text strong {
    display: inline-block;
    width: auto;
  }
}
@media screen and (max-width: 766px) {
  .future-content {
    padding: 40px 0 20px;
  }
  .future-reserveseat .price-counter a {
    width: 100%;
    padding: 12px 0;
  }
  .future-reserveseat .price-counter .counter-offer-ends-text {
    font-size: 15px;
  }
  .future-reserveseat .price-counter .counter-offer-ends-text .offer-time-end {
    font-size: 15px;
  }
  .future-title {
    font-size: 30px;
  }
  .future-title span {
    font-size: 30px;
  }
  .feature-text-section {
    padding: 25px;
  }
  .feature-mentor-text p {
    font-size: 17px;
  }
  .feature_card-container {
    padding: 0 20px;
  }
  .feature-ul-bar {
    flex-wrap: wrap;
  }
  .feature-ul-bar ul {
    width: 100% !important;
  }
  .feature-ul-bar ul li {
    padding-left: 30px;
  }
  .feature-ul-bar ul li::before {
    width: 22px;
    height: 22px;
    top: 4px;
    background-size: 100%;
  }
}
