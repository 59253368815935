.WhatWillYou__container {
  max-width: 1120px;
  margin: 0 auto;
  padding: 70px 20px 0;
}

.meet-your-title {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.meet-your-title span {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  background: linear-gradient(
    132.64deg,
    #0081ee -15.13%,
    #01b9e2 1.56%,
    #fe5b48 17.6%,
    #fe5b48 33.85%,
    #fdb918 45.27%,
    #f0a039 53.64%,
    #f0a039 71.05%,
    #8026c1 108.71%,
    #e73598 112.88%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.what-will-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 32px 0 0;
}
.what-will-text-box {
  width: 50%;
  padding: 0 24px;
}
.what-will-text {
  background: rgba(217, 217, 217, 0.05);
  border-radius: 4px;
  background-image: url("../assets/images/meet-box-bg.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;
  height: 100%;
  padding: 30px;
}
.what-will-text .will-title {
  display: inline-block;
  font-family: "Qanelas Soft";
  font-size: 34.16px;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(
    135.57deg,
    #0081ee -102.7%,
    #01b9e2 -38.75%,
    #fe5b48 16.08%,
    #f0a039 103.1%,
    #8026c1 153.12%,
    #e73598 217.08%
  );
  border-radius: 4px;
  padding: 3px 42px;
}
.what-will-text ul {
  padding: 30px 0 0 25px;
  margin: 0;
}
.what-will-text ul li {
  font-family: "Qanelas Soft";
  font-size: 20.13px;
  font-weight: 400;
  color: #fff;
  padding: 2px 0;
}
.what-will-text-box.c {
  margin-top: 55px;
  text-align: center;
}
.industrial-text {
  font-family: "Qanelas Soft";
  font-size: 27.13px;
  font-weight: 400;
  color: #fff;
  padding-top: 25px;
}
.industrial-text strong {
  font-family: "Qanelas Soft";
  font-size: 27.13px;
  font-weight: 700;
  background: linear-gradient(
    135.57deg,
    #0081ee -102.7%,
    #01b9e2 -38.75%,
    #fe5b48 16.08%,
    #f0a039 103.1%,
    #8026c1 153.12%,
    #e73598 217.08%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
@media screen and (max-width: 1024px) {
  .mentor {
    padding: 40px 0 0;
  }
  .meet-your-title {
    font-size: 48px;
  }
  .meet-your-title span {
    font-size: 48px;
  }
  .meet-title-block {
    padding-bottom: 30px;
  }
  .mentor__card-container {
    flex-wrap: wrap;
  }
  .mentor-block {
    width: 70%;
  }
  .mentor__card--name {
    font-size: 20px;
  }
  .what-will-text .will-title {
    font-size: 20px;
  }
  .what-will-text ul li {
    font-size: 14px;
    padding: 5px 0;
  }
  .industrial-text strong {
    font-size: 18px;
  }
  .industrial-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 766px) {
  .mentor {
    padding: 10px 0 0;
  }
  .meet-your-title {
    font-size: 30px;
  }
  .meet-your-title span {
    font-size: 29px;
  }
  .mentor__card-container {
    padding-top: 0;
  }
  .mentor-block {
    width: 100%;
  }
  .mentor__card--image {
    width: 80px;
    height: 80px;
  }
  .mentor__card--name {
    font-size: 18px;
  }
  .mentor__card--desc {
    font-size: 15px;
  }
  .WhatWillYou__container {
    padding-top: 0;
  }
  .what-will-text-box {
    width: 100%;
    padding: 0;
  }
  .what-will-text {
    padding: 20px;
  }
  .what-will-text-box.c {
    margin-top: 0;
    text-align: left;
  }
  .industrial-text {
    font-size: 14px;
  }
  .industrial-text strong {
    font-size: 17px;
  }
}
