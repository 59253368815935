.certificates__container {
  padding: 30px;
}

.cert--img-block {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.cert-ttl-blk {
  margin-bottom: 24px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
}

.project__title-text {
  font-family: "Manrope", sans-serif;
  font-size: 50px;
  text-align: center;
}

.project__title-textB {
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-size: 32px;
  font-weight: 800;
}

.cert--image-holder {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
  align-self: center;
}

.cert--bosT {
  width: 100px;
  height: 30px;
  align-self: flex-start;
}

.cert--bosT-text span {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  color: #2e95c9;
  font-weight: 800;
}

.cert--bosT-textB {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #0b314f;
}

.cert--bosT-textR {
  font-size: 13px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #0b314f;
}

.cert--bosM {
  margin-top: -70px;
  align-self: center;
}

.cert--bosB {
  align-self: center;
}

.project__image {
  width: 100%;
  /* height: 300px; */
  /* object-fit: cover; */
}

.project__text {
  margin-bottom: 10px;
  color: #0b314f;
  font-size: 13px;
  font-family: "Manrope", sans-serif;
  font-weight: 300;
  padding: 10px 0;
}

.cert__title-text {
  font-family: "Manrope", sans-serif;
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
}

.cert__title-text span {
  font-family: "Manrope", sans-serif;
  font-size: 32px;
  font-weight: 800;
}

.cert__imgs-container {
  /* display: flex;
    flex-wrap: wrap; */
}

.cert__imgs--block {
  display: flex;
  justify-content: space-between;
}
.cert--mesh {
  /* background-image: url("../assets//images/mesh.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
  background-image: linear-gradient(#f5f5f5 0em, transparent 0.1em),
    linear-gradient(270deg, #f5f5f5 0em, transparent 0.1em);
  background-size: 1.5em 1.5em;
}

.slider__im {
  padding: 20px;
}

.cert-img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.cert--mesh {
  margin: 0 auto 0;
  max-width: 1200px;
}

.certificate {
  width: 100%;
  max-width: 1275px;
  padding-top: 50px;
}
.cert--image-holder {
  width: 100%;
  max-width: 1140px;
}
.cert--text-holder {
  max-width: 1140px;
  font-family: "Manrope", sans-serif;
}
.cert--bosT-text-main {
  display: flex;
  justify-content: space-between;
}
.cert--bosT-text-main .cert--bosB {
  max-width: 290px;
  width: 100%;
  margin-top: -20px;
}

.certificates-carousel {
  display: inline-block;
  width: 100%;
  padding: 170px 100px 210px;
}
.certificates-carousel .css-1fzpoyk {
  width: 675px;
}
.mobile-bosB-image {
  display: none;
}
.partnership-img-item {
  text-align: center;
  padding: 80px 30px 40px;
}
.cert__imgs--block-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cert__imgs--block-item .cert__img-item {
  padding: 0 10px;
}

@media (max-width: 1139px) {
  .certificates-carousel .css-1fzpoyk {
    width: 550px;
  }
  .cert__imgs--block .cert__img {
    height: 80px;
  }
  .certificates-carousel {
    padding: 130px 100px 130px;
  }
}

@media (max-width: 1024px) {
  .cert__imgs--block .cert__img {
    height: 60px;
  }
  .certificates-carousel .css-1fzpoyk {
    width: 410px;
  }
  .certificates-carousel {
    padding: 100px 100px 60px;
  }
  .cert--bosM {
    margin-top: -20px;
  }
}

@media (max-width: 766px) {
  .project__title-text {
    font-size: 36px;
  }
  .project__title-textB {
    font-size: 46px;
  }
  .certificate {
    padding-top: 0;
  }
  .project__text {
    font-size: 16px;
    line-height: 20px;
  }
  .cert__title-text {
    font-size: 36px;
  }
  .cert__title-text span {
    font-size: 46px;
  }
  .cert__imgs--block {
    justify-content: center;
    margin-bottom: 0;
  }
  .cert__imgs--block img {
    height: 60px;
  }
  .certificates-carousel {
    padding: 70px 0 30px;
  }
  .certificates-carousel .css-1fzpoyk {
    width: 50%;
  }
  .cert--bosT-text-main {
    align-items: center;
    margin-top: -8px;
  }
  .cert--bosT-text-main .cert--bosB {
    width: 130px;
    margin-top: 0;
  }
  .cert--bosM {
    margin-top: 20px;
  }
  .cert--mesh {
    margin-top: 10px;
  }
  .cert__imgs--block .cert__img {
    height: 60px;
  }
  .certificates__container {
    padding: 7px 20px;
  }
  .cert-ttl-blk {
    margin-bottom: 16px;
  }
  .cert--image-holder {
    padding-bottom: 0;
  }
  .mobile-bosB-image {
    display: block;
    text-align: center;
  }
  .cert__imgs--block-item {
    justify-content: center;
  }
  .cert__imgs--block-item .cert__img-item {
    padding: 8px 10px;
  }
  .partnership-img-item {
    text-align: center;
    padding: 10px 0px 10px;
  }
}
@media (max-width: 414px) {
  .certificates-carousel {
    padding: 30px 0 0px;
  }
  .cert__title-text {
    font-size: 32px;
    padding-bottom: 10px;
  }
  .cert__title-text span {
    font-size: 36px;
  }
}
@media (min-width: 576px) {
  .project__image {
    height: 400px;
    width: 100%;
  }
  .stories__container .slider__item img {
    height: auto !important;
  }
}

@media (min-width: 767px) {
  .certificates__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .certificate {
    width: 100%;
  }

  .project__text {
    font-size: 28px;
  }

  .cert__imgs-container {
    /* flex-wrap: nowrap;
        justify-content: center;
        gap: 20px; */
    display: flex;
    justify-content: center;
  }
  .cert--img-block {
    align-items: center;
  }

  .cert--image-holder {
    width: 100%;
  }

  .cert--text-holder {
    width: 100%;
  }

  .cert--bosT {
    width: 40%;
    height: 30px;
    align-self: flex-start;
  }

  .cert--bosT-textR {
    font-size: 27px;
  }

  .cert--bosT-text span {
    font-size: 50px;
  }
  .cert-ttl-blk {
    display: flex;
    align-items: baseline;
    gap: 10px;
  }

  .project__title-text {
    font-size: 50px;
  }

  .project__title-textB {
    font-size: 50px;
  }

  .cert__title-text {
    font-size: 50px;
  }

  .cert__title-text span {
    font-size: 50px;
  }

  .cert-img {
    height: 250px;
  }
}

@media (min-width: 1023px) {
  .certificate {
    width: 100%;
  }

  .project__text {
    font-size: 28px;
  }

  .cert--image-holder {
    width: 100%;
  }

  .cert--text-holder {
    width: 100%;
  }

  .cert--bosT {
    width: 50%;
    height: 30px;
    align-self: flex-start;
  }

  /* .cert--bosT-text p {
        font-size: 22px;
    } */

  .cert--bosT-textB {
    font-size: 50px;
  }

  .cert--bosT-textB span {
    font-size: 50px;
  }

  .cert--bosT-textR {
    font-size: 27px;
  }

  .cert--image-holder {
    padding-bottom: 0px;
  }
  .cert__title-text {
    font-size: 50px;
  }

  .cert__title-text span {
    font-size: 50px;
  }

  .cert-img {
    height: 300px;
  }
}
