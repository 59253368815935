.before-you-ask-section {
  padding: 122px 0 0;
}
.before-you-ask_container {
  max-width: 1120px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
.before-you-ask-title h2 {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.before-you-ask-title h2 span {
  font-family: "Qanelas Soft";
  font-size: 60px;
  font-weight: 600;
  background: linear-gradient(
    132.64deg,
    #0081ee -15.13%,
    #01b9e2 1.56%,
    #fe5b48 17.6%,
    #fe5b48 33.85%,
    #fdb918 45.27%,
    #f0a039 53.64%,
    #f0a039 71.05%,
    #8026c1 108.71%,
    #e73598 112.88%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.before-you-ask-title p {
  font-family: "Qanelas Soft";
  font-size: 23.36px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  padding-top: 8px;
}
.trust-bar {
  display: flex;
  align-items: start;
  padding: 50px 0;
}
.trust-vidio-tex {
  width: 25%;
  padding: 0 3px;
}
.trust-vidio-img {
  display: inline-block;
  width: 100%;
  background: #3f3f3f;
  border-radius: 10px;
  margin: 1px 0;
}
.trust-vidio-img img {
  display: block;
}

.also-there-content p {
  font-family: "Qanelas Soft";
  font-size: 23.36px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  text-align: center;
}
.also-there-bar {
  display: flex;
  align-items: self-start;
  justify-content: center;
  padding-top: 50px;
}
.also-vidio-box {
  width: 25%;
  background: #3f3f3f;
  border-radius: 10px;
  margin: 0 3px;
}

@media screen and (max-width: 1024px) {
  .before-you-ask-section {
    padding: 100px 0 0px;
  }
  .before-you-ask-title h2 {
    font-size: 48px;
  }
  .before-you-ask-title h2 span {
    font-size: 48px;
  }
  .before-you-ask-title p {
    font-size: 20px;
  }
  .validate-text {
    padding: 10px 0;
  }
  .validate-text h2 {
    font-size: 22px;
  }
  .validate-text p {
    font-size: 15px;
  }
}
@media screen and (max-width: 766px) {
  .before-you-ask-section {
    padding: 50px 0 0px;
  }
  .before-you-ask-title h2 {
    font-size: 30px;
  }
  .before-you-ask-title h2 span {
    font-size: 30px;
  }
  .before-you-ask-text {
    padding-top: 30px;
  }
  .before-you-ask-left {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .before-you-ask-right {
    width: 100%;
  }
  .validate-text {
    padding: 15px 0;
  }
  .validate-text h2 {
    font-size: 25px;
  }
  .validate-text p {
    font-size: 16px;
  }
  .trust-us-vidio {
    margin: 0 -23px;
  }
  .trust-bar {
    padding: 30px 0 0;
    overflow-x: auto;
    margin-bottom: 30px;
  }
  .trust-vidio-tex {
    width: 100%;
    padding: 0 3px;
    min-width: 130px;
  }
  .also-there-content p {
    font-size: 20px;
  }
  .also-there-bar {
    padding: 40px 0 20px;
    overflow-x: scroll;
  }
  .also-vidio-box {
    width: 100%;
    padding: 0 3px;
    min-width: 134px;
  }
  .also-vidio-box video {
    height: 200px !important;
  }
  .trust-us-vidio {
    display: none;
  }
}
